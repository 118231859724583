/**
 * @module RouteDialogs
 * @author David Kirkland <david.kirkland@nec.com.au>
 */
import { useState, useRef } from 'react';
import Draggable from 'react-draggable';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip from '@mui/material/Tooltip';
import FileOpenIcon from '@mui/icons-material/FileOpenOutlined';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import RefreshIcon from '@mui/icons-material/RefreshOutlined';
import { Collection, Card, Heading, Text, Flex, Divider, Badge } from '@aws-amplify/ui-react';
import {
    routeActionReview, routeActionApprove, routeActionArchive, routeActionRestore,
    routeActionDeleteDraft, routeActionDeleteReview
} from './ManageRoutes';
import {
    storageFolderDraft, storageFolderReview, storageFolderApproved, storageFolderArchive,
} from './AwsFunctions';


/**
 * Create a dialog for managing route versions
 * @param {{}} props 
 * @returns the dialog to be rendered
 */
function ManageAwsRouteDialog(props) {
    const [selectedAction, setSelectedAction] = useState("");
    const draggableRef = useRef('');

    const handleOk = () => {
        props.onOk(selectedAction);
        setSelectedAction("");
    }

    const handleCancel = () => {
        props.onCancel();
        setSelectedAction("");
    }

    const paperComponent = (props) => {
        return (
            <Draggable nodeRef={draggableRef}>
                <div ref={draggableRef}>
                    <Paper {...props} />
                </div>
            </Draggable>
        );
    }

    return (
        <Dialog
            open={props.content !== null}
            aria-labelledby="manage-route-dialog-title"
            PaperComponent={paperComponent}
            onClose={handleCancel}
            fullWidth={true}
            maxWidth="lg"
        >
            <DialogTitle style={{ cursor: 'move' }} id="manage-route-dialog-title">
                {props.content && ("Route " + props.content.route + " - " + props.content.destination +
                    (props.content.variant ? " (" + props.content.variant + ")" : ""))}
            </DialogTitle>
            <DialogContent>
                <Heading level={6}>Route Versions</Heading>
                <Collection
                    type="list"
                    items={props.content && props.content.versions}
                    gap="0.25rem"
                >
                    {(item, index) => (
                        <Card
                            key={index}
                            padding="0.25rem"
                            backgroundColor={"LightGray"}
                        >
                            {(item.folder === storageFolderDraft) && <Badge size="small" variation="info">Draft</Badge>}
                            {(item.folder === storageFolderReview) && <Badge size="small" variation="warning">Under Review</Badge>}
                            {(item.folder === storageFolderApproved) && <Badge size="small" variation="success">Approved</Badge>}
                            {(item.folder === storageFolderArchive) && <Badge size="small">Archive</Badge>}
                            <Text fontSize="1rem">{"" + item.lastModified}</Text>
                        </Card>
                    )}
                </Collection>


            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
                <FormControl sx={{ margin: 1, minWidth: "15rem" }} size="small">
                    <InputLabel id="select-action-label">Action</InputLabel>
                    <Select
                        labelId="select-action-label"
                        id="select-action"
                        label="Action"
                        value={selectedAction}
                        autoWidth
                        onChange={(event) => setSelectedAction(event.target.value)}
                    >
                        <MenuItem value=""><em>None</em></MenuItem>
                        <MenuItem disabled={!props.content || !props.content.folders.includes(storageFolderDraft)} value={routeActionReview}>Send Draft for Review</MenuItem>
                        <MenuItem disabled={!props.content || !props.content.folders.includes(storageFolderDraft)} value={routeActionDeleteDraft}>Delete Draft Route</MenuItem>
                        <MenuItem disabled={!props.content || !props.content.folders.includes(storageFolderReview)} value={routeActionApprove}>Approve Reviewed Route</MenuItem>
                        <MenuItem disabled={!props.content || !props.content.folders.includes(storageFolderReview)} value={routeActionDeleteReview}>Delete Reviewed Route</MenuItem>
                        <MenuItem disabled={!props.content || !props.content.folders.includes(storageFolderApproved)} value={routeActionArchive}>Archive Approved Route</MenuItem>
                        <MenuItem disabled={!props.content || !props.content.folders.includes(storageFolderArchive)} value={routeActionRestore}>Restore Archived Route</MenuItem>
                    </Select>
                </FormControl>

                <Button variant="outlined" style={{ minWidth: '6rem' }} onClick={handleCancel}>Cancel</Button>
                <Button disabled={!selectedAction} variant="contained" style={{ minWidth: '6rem' }} onClick={handleOk}>OK</Button>
            </DialogActions>
        </Dialog>
    );
}

/**
 * Create a dialog to select a route from AWS
 * @param {{}} props 
 * @returns dialog to be rendered
 */
function SelectAwsRouteDialog(props) {
    const [selectedItemKey, setSelectedItemKey] = useState(null);
    const [selectedRouteFolder, setSelectedRouteFolder] = useState(storageFolderDraft);

    const handleAction = (key) => {
        props.onOk(key);
    }

    return (
        <Dialog
            open={props.show}
            fullWidth={true}
            maxWidth="md"
            aria-labelledby="select-route-dialog-title"
            PaperProps={{
                sx: {
                    minHeight: "80%"
                }
            }}
        >

            <DialogTitle id="select-route-dialog-title">
                <div><Heading level={3}>Select Route</Heading></div>
            </DialogTitle>

            <DialogContent>
                <AwsRouteList
                    listItems={props.content && props.content.filter(val => val.folder === selectedRouteFolder)}
                    defaultFolder={selectedRouteFolder}
                    onSelectedFolderChange={(folder) => { setSelectedRouteFolder(folder) }}
                    onClick={(key) => { setSelectedItemKey(key) }}
                    onDoubleClick={(key) => { handleAction(key) }}
                />
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
                <Button
                    variant="outlined"
                    style={{ minWidth: '8rem' }}
                    startIcon={<CancelIcon />}
                    onClick={() => { handleAction(null) }}
                    autoFocus>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    style={{ minWidth: '8rem' }}
                    disabled={selectedItemKey === null}
                    startIcon={<FileOpenIcon />}
                    onClick={() => { handleAction(selectedItemKey) }}>
                    Open
                </Button>
            </DialogActions>
        </Dialog>
    );
}

/**
 * Create a list of routes that exist in AWS
 * @param {{}} props 
 * @returns route list component to be rendered
 */
function AwsRouteList(props) {
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedRouteFolder, setSelectedRouteFolder] = useState(props.defaultFolder);

    return (
        <Container maxWidth={false}>
            <Heading level={5}>Route State</Heading>
            <Flex direction="row" alignItems="center" justifyContent="space-between" gap="0.25rem">
                <RadioGroup
                    aria-labelledby="select-route-status-radio-buttons-group-label"
                    value={selectedRouteFolder}
                    name="select-route-status-radio-buttons-group"
                    row
                    onChange={(event) => {
                        setSelectedRouteFolder(event.target.value);
                        props.onSelectedFolderChange(event.target.value);
                        // clear the selected item when changing the route folder
                        setSelectedItems([]);
                        props.onClick(null);
                    }}
                >
                    {props.showAllOption && <FormControlLabel value="" control={<Radio />} label="All" />}
                    <FormControlLabel value={storageFolderDraft} control={<Radio />} label="Draft" />
                    <FormControlLabel value={storageFolderReview} control={<Radio />} label="Review" />
                    <FormControlLabel value={storageFolderApproved} control={<Radio />} label="Approved" />
                    {props.showArchiveOption && <FormControlLabel value={storageFolderArchive} control={<Radio />} label="Archive" />}
                </RadioGroup>
                {props.onRefreshList &&
                    <Tooltip title="Refresh the list of routes">
                        <span>
                            <IconButton
                                aria-label="refresh"
                                onClick={() => props.onRefreshList()}
                                size="large"
                            >
                                <RefreshIcon fontSize="inherit" />
                            </IconButton>
                        </span></Tooltip>
                }
            </Flex>
            <Divider orientation="horizontal" style={{ padding: '0.25rem' }} />
            <br />
            <RouteList
                itemsPerPage={props.itemsPerPage}
                listItems={props.listItems}
                selectedItems={selectedItems}
                showFolders={props.showFolders}
                onClick={(key) => {
                    setSelectedItems(selectedItems.includes(key) ? [] : [key]);
                    props.onClick(key);
                }}
                onDoubleClick={props.onDoubleClick}
            />
            <br />
        </Container>
    );
}

/**
 * Display a list of routes
 * @param {{}} props 
 * @returns route list component to be rendered
 */
function RouteList(props) {
    return (
        <Collection
            type="list"
            isSearchable
            searchPlaceholder="Type to search..."
            searchNoResultsFound={
                <Flex justifyContent="center">
                    <Text color="purple.80" fontSize="1rem">
                        No routes found
                    </Text>
                </Flex>
            }
            searchFilter={(routes, keyword) => (
                routes.route.toLowerCase().startsWith(keyword.toLowerCase()) ||
                routes.destination.toLowerCase().search(keyword.toLowerCase()) >= 0
            )}
            isPaginated
            itemsPerPage={props.itemsPerPage || 10}
            items={props.listItems}
            gap="0.25rem"
        >
            {(item, index) => (
                <Card
                    key={index}
                    padding="0.25rem"
                    backgroundColor={props.selectedItems.includes(item.key) ? "DodgerBlue" : "LightGray"}
                    onClick={() => { props.onClick(item.key) }}
                    onDoubleClick={() => { props.onDoubleClick(item.key) }}
                >
                    <Flex direction="row" alignItems="center" justifyContent="space-between" gap="0.25rem">
                        <Flex direction="column" alignItems="flex-start" gap="0rem">
                            <Heading level={6}>{item.route + " - " + item.destination}</Heading>
                            <Text fontSize="0.75rem">{"Variant: " + (item.variant || "N/A")}</Text>
                        </Flex>
                        {props.showFolders &&
                            <Flex direction="row" alignItems="flex-start" gap="0.25rem">
                                {item.folders.includes(storageFolderDraft) && <Badge size="small" variation="info">Draft</Badge>}
                                {item.folders.includes(storageFolderReview) && <Badge size="small" variation="warning">Under Review</Badge>}
                                {item.folders.includes(storageFolderApproved) && <Badge size="small" variation="success">Approved</Badge>}
                                {item.folders.includes(storageFolderArchive) && <Badge size="small">Archive</Badge>}
                            </Flex>
                        }
                    </Flex>
                </Card>
            )}
        </Collection>
    );
}

export {
    ManageAwsRouteDialog, SelectAwsRouteDialog, AwsRouteList, RouteList
};